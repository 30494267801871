<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Edit Product</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'photographer_info', params: {id: $route.params.eid}}" class="btn btn-gray btn-subheader">Back</router-link>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="updatepersonalinfo">
        <div class="errorrow">
          <!-- <pre>{{errors}}</pre> -->
          <!-- <div class="errorsmall" v-if="signup.submitted && personalinfo.loginid.$error.pattren">Loginid is not valid</span>
            <span class="error errorbatch" ng-show="signup.submitted && personalinfo.loginid.$error.maxlength">Loginid is Exceded 20 Characters </span> -->
          <span class="errorsmall" v-show="error">{{errorreport}}</span>

        </div>
        <div class="row">

          <div class="col-sm-6 mb20">
            <label>Alphabet</label>
              <input type="text" class="form-control" placeholder="Alphabet" name="Alphabet" id="alpha" :class="{ 'is-danger': errors.has( 'Alphabet')}" v-validate="'required'" v-model="product.alphabet">
              <span class="error_text" v-if="errors.has('Alphabet')">{{errors.first('Alphabet')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Chemical Name</label>
            <!-- <span class="errorsmall" v-if="emailidavail != ''">{{emailidavail}}</span> -->
              <input type="text" class="form-control" placeholder="Chemical Name" name="chemical name" id="cname" :class="{ 'is-danger': errors.has( 'chemical name')}"  v-validate="'required'" v-model="product.productname" readonly>
              <span class="error_text" v-if="errors.has('chemical name')">{{errors.first('chemical name')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Cas No</label>
              <input type="text" class="form-control" placeholder="Cas No"  name="cas no" id="casno" :class="{ 'is-danger': errors.has( 'cas no')}"  v-validate="'required'" v-model="product.casno">
              <span class="error_text" v-if="errors.has('cas no')">{{errors.first('cas no')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Cat No</label>
              <input type="text" class="form-control" placeholder="Cat No"  name="cat no" id="catno" :class="{ 'is-danger': errors.has( 'cat no')}"  v-validate="'required'" v-model="product.catno">
              <span class="error_text" v-if="errors.has('cat no')">{{errors.first('cat no')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Apperance</label>
              <input type="text" class="form-control" placeholder="Apperance"  name="Apperance" id="apperance" :class="{ 'is-danger': errors.has( 'Apperance')}"  v-validate="'required'" v-model="product.appearance">
              <span class="error_text" v-if="errors.has('Apperance')">{{errors.first('Apperance')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Flash Point</label>
              <input type="text" class="form-control" placeholder="Flash Point"  name="flash point" id="flashpoint" :class="{ 'is-danger': errors.has( 'flash point')}"  v-validate="'required'" v-model="product.fpoint">
              <span class="error_text" v-if="errors.has('flash point')">{{errors.first('flash point')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>HSN code</label>
              <input type="text" class="form-control" placeholder="HSN code"  name="hsn code" id="hsncode" :class="{ 'is-danger': errors.has( 'HSN code')}"  v-validate="'required'"  v-model="product.hsncode">
              <span class="error_text" v-if="errors.has('HSN code')">{{errors.first('HSN code')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>GST (%)</label>
              <input type="text" class="form-control" placeholder="GST"  name="gst" id="gst" :class="{ 'is-danger': errors.has( 'gst')}"  v-validate="'required'"  v-model="product.gst">
              <span class="error_text" v-if="errors.has('GST')">{{errors.first('GST')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Storage</label>
              <input type="text" class="form-control" placeholder="Storage"  name="storage" id="storage" :class="{ 'is-danger': errors.has( 'storage')}"  v-validate="'required'" v-model="product.storage">
              <span class="error_text" v-if="errors.has('storage')">{{errors.first('storage')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Bp.</label>
              <input type="text" class="form-control" placeholder="Bp."  name="Bp." id="bp" :class="{ 'is-danger': errors.has( 'Bp.')}"  v-validate="'required'" v-model="product.bp">
              <span class="error_text" v-if="errors.has('Bp.')">{{errors.first('Bp.')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Mp.</label>
              <input type="text" class="form-control" placeholder="Mp."  name="Mp." id="mp" :class="{ 'is-danger': errors.has( 'Mp.')}"  v-validate="'required'" v-model="product.mp">
              <span class="error_text" v-if="errors.has('Mp.')">{{errors.first('Mp.')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Solubility</label>
              <input type="text" class="form-control" placeholder="Solubility"  name="Solubility" id="solubility" v-model="product.solubility">
              <span class="error_text" v-if="errors.has('Solubility')">{{errors.first('Solubility')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Density</label>
              <input type="text" class="form-control" placeholder="Density"  name="Density" id="density" :class="{ 'is-danger': errors.has( 'Density')}"  v-validate="'required'"  v-model="product.density">
              <span class="error_text" v-if="errors.has('Density')">{{errors.first('Density')}}</span>
          </div>

          <div class="col-sm-6 mb20">
            <label>Formula</label>
              <input type="text" class="form-control" placeholder="Formula" name="formula" id="formula" :class="{ 'is-danger': errors.has( 'formula')}"  v-validate="'required'" v-model="product.moleformula">
              <span class="error_text" v-if="errors.has('formula')">{{errors.first('formula')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Molecular Weight</label>
              <input type="text" class="form-control" placeholder="Molecular Weight"  name="molecular weight" id="moleweight" :class="{ 'is-danger': errors.has( 'molecular weight')}"  v-validate="'required'" v-model="product.moleweight">
              <span class="error_text" v-if="errors.has('molecular weight')">{{errors.first('molecular weight')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Purity</label>
              <input type="text" class="form-control" placeholder="Purity"  name="Purity" id="purity" :class="{ 'is-danger': errors.has( 'Purity')}"  v-validate="'required'" v-model="product.purity">
              <span class="error_text" v-if="errors.has('Purity')">{{errors.first('Purity')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>NLT Purity</label>
              <input type="text" class="form-control" placeholder="NLT Purity"  name="NLT Purity" id="nltpurity" :class="{ 'is-danger': errors.has( 'NLT Purity')}"  v-validate="'required'" v-model="product.nltpurity">
              <span class="error_text" v-if="errors.has('NLT Purity')">{{errors.first('NLT Purity')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Quantities</label>
              <input type="text" class="form-control" placeholder="Quantities"  name="Quantities" id="quantities" :class="{ 'is-danger': errors.has( 'Quantities')}"  v-validate="'required'"  v-model="product.quantities">
              <span class="error_text" v-if="errors.has('Quantities')">{{errors.first('Quantities')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Prices</label>
              <input type="text" class="form-control" placeholder="Prices"  name="Prices" id="prices" :class="{ 'is-danger': errors.has( 'Prices')}"  v-validate="'required'" v-model="product.prices">
              <span class="error_text" v-if="errors.has('Prices')">{{errors.first('Prices')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Availability</label>
              <input type="text" class="form-control" placeholder="Availability"  name="Availability" id="availability" :class="{ 'is-danger': errors.has( 'Availability')}"  v-validate="'required'"  v-model="product.availability">
              <span class="error_text" v-if="errors.has('Availability')">{{errors.first('Availability')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Product Code</label>
              <input type="text" class="form-control" placeholder="Product Code"  name="ProductCode" id="productcode" :class="{ 'is-danger': errors.has( 'productcode')}"  v-validate="'required'"  v-model="product.productcode">
              <span class="error_text" v-if="errors.has('productcode')">{{errors.first('productcode')}}</span>
          </div>
          <!-- <div class="col-sm-6 mb20 pt-4">
            <div class="form-check form-check-inline">
                <label class="form-check-label mr-2" for="inlineCheckbox1">MTO</label>
  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" v-model="product.mto">

</div>
<div class="form-check form-check-inline">
    <label class="form-check-label mr-2" for="inlineCheckbox2">POR</label>
  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" v-model="product.por">

</div>
</div> -->

<div class="col-12 mb20">
  <div class="flexrow ">
    <div class="subheader_flex">
      <h2>Structure </h2>
    </div>
    <div class="flexcircle">
      <div class="upload-btn-wrapper">
        <button class="btn btn-green">Edit Structure</button>
        <input type="file" id="files" ref="files"  v-on:change="handleFilesUpload($event)" />
      </div>
    </div>
  </div>
  <div class="col-12" v-if="files.length == 0">
    <img :src="product.structure" :alt="image"  class="imagewidth img-fluid" width="300" height="300" />
  </div>
  <div class="clearfix col-12 filebox " v-if="showstrsec == true" >
    <div class="uploadmess text-center" v-if="files.length === 0">
      Please click Upload Structure button to add Structure
    </div>
    <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
      <div class="flexrow fixeddim">
        <!-- <div class="filename subheader_flex ">{{ file.filee.name }} </div> -->
        <img :ref="'image'" class="img-fluid"/>
        <div class="file_btn flexcircle">
          <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
        </div>
      </div>
    </div>
  </div>
</div>
          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edit_photographer',
  data () {
    return {
      error: false,
      errorreport: '',
      product: {},
      files: [],
      showloader: true,
      showstrsec: false
    }
  },
  created () {
    this.loadinfo()
  },
  methods: {
  loadinfo () {
    this.$http.get('/pgmanagement/pginfo/' + this.$route.params.eid).then((res) => {
      this.product = res.data.details[0]

      this.showloader = false
    })
  },
  updatepersonalinfo () {
    this.showloader = true
    this.$validator.validateAll().then((result) => {
      if (result) {
        if (this.files.length !== 0) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }
            formData.append('alphabet', this.product.alphabet);
            formData.append('productname', this.product.productname);
            formData.append('casno', this.product.casno);
            formData.append('catno', this.product.catno);
            formData.append('appearance', this.product.appearance);
            formData.append('fpoint', this.product.fpoint);
            formData.append('hsncode', this.product.hsncode);
            formData.append('gst', this.product.gst);
            formData.append('storage', this.product.storage);
            formData.append('bp', this.product.bp);
            formData.append('mp', this.product.mp);
            formData.append('solubility', this.product.solubility);
            formData.append('density', this.product.density);
            formData.append('moleformula', this.product.moleformula);
            formData.append('moleweight', this.product.moleweight);
            formData.append('purity', this.product.purity);
            formData.append('nltpurity', this.product.nltpurity);
            formData.append('quantities', this.product.quantities);
            formData.append('prices', this.product.prices);
            formData.append('availability', this.product.availability);
            formData.append('productcode', this.product.productcode);

            this.$http.put('/pgmanagement/updatepg/'+this.$route.params.eid,  formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((response) => {
              if (response.data.success === true) {
                  this.$router.push('/product_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          } else {
            this.$http.put('/pgmanagement/updatepgwithoutimage/' + this.$route.params.eid, this.product).then((response) => {
              if (response.data.success === true) {
                  this.$router.push('/product_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          }
      }  else {
        this.showloader = false
      }
    })
  },
  handleFilesUpload () {
    this.showstrsec = true
    // this.files = []
    let uploadedFiles = this.$refs.files.files
    /*
      Adds the uploaded file to the files array
    */
    for (var i = 0; i < uploadedFiles.length; i++) {
      this.files.push(uploadedFiles[i])
    }
    for (let i = 0; i < this.files.length; i++) {
      let reader = new FileReader();
     reader.onload = (e) => {
        this.$refs.image[i].src = reader.result;
console.log(e)
      };

      reader.readAsDataURL(this.files[i]);
    }
  },
  removeFile (key) {
      this.showstrsec = false
    this.files.splice(key, 1)
  }
}
}
</script>

<style lang="css" scoped>

</style>
